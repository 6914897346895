<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：开单主列表页
	开始时间：2021-01-18
	开发人员：刘巍骏
	最后修改：2021-01-18
	备注说明：如需修改请联系开发人员
-->
<template>
	<div class="billing_listAll">
    <!--  -->
    <div class="list_head">

    </div>

    <!-- 条件筛选操作框 -->
    <div class="list_operate">
      <ul>
        <li>查看:</li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              业务员<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item>双皮奶</el-dropdown-item>
              <el-dropdown-item>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              客户列表<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item>双皮奶</el-dropdown-item>
              <el-dropdown-item>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              类型<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item>双皮奶</el-dropdown-item>
              <el-dropdown-item>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              状态<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item>双皮奶</el-dropdown-item>
              <el-dropdown-item>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              时间<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item>双皮奶</el-dropdown-item>
              <el-dropdown-item>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>导出</li>
      </ul>
    </div>

    <!-- 中间内容 -->
    <div class="list_content scollStyle">
      <table>
        <thead>
          <tr>
            <th style="width:10%;">客户名称</th>
            <th>订单号</th>
            <th>订单类型</th>
            <th>金额</th>
            <th>状态</th>
            <th>下单时间</th>
            <th>所属人</th>
            <th>进度条测试</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(elem,index) in 20" @click="$router.push('/billing_menu');">
            <td>
              <span class="text">
                客户测试
              </span>
            </td>
            <td>订单测试</td>
            <td>类型测试</td>
            <td>金额测试</td>
            <td @mouseenter="order_index=index" @mouseleave="order_index=-1">
              状态测试
              <div class="table_absolute" v-show="order_index==index">
                流程测试框
              </div>
            </td>
            <td>下单时间测试</td>
            <td>所属人测试</td>
            <td>
              <el-progress :percentage="20" :color="'@theme'"></el-progress>
            </td>
            <td>
              <span class="operate" title="删除">
                <i class="el-icon-delete"></i>
              </span>
              <span class="operate">
                <i class="el-icon-s-tools"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 底部分页 -->
    <div class="list_buttom">
      <el-pagination
        layout="prev, pager, next"
        :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import store from '@/vuex/store'

  export default{
    name:'billingIndex',
    data(){
      return{
        // 正在操作下标
        order_index:-1,//订单表格行
      }
    },
    mounted() {
      this.$store.state.routerLoading = false
    }
  }
</script>

<style lang="less">
  .billing_listAll{
    width: 100%;
    height: 100%;
    position: relative;
    // border: 1px solid black;
    .list_head{
      height: 40px;
      border-bottom: 1px solid #e1eefb;
    }
    // 开单头部测试
    .list_operate{
      height: 40px;
      width: 100%;
      border-bottom: 1px solid #e1eefb;
      ul{
        height: 100%;
        display: flex;
        font-size: 14px;
        cursor: pointer;
        li{
          padding: 8px 20px;
          // border: 1px solid black;
        }
      }
    }
    // 开单主页内容
    .list_content{
      // padding: 10px 0;
      overflow: auto;
      height: 70vh;
      // border: 1px solid black;
      table{
        width: 100%;
        font-size: 12px;
        table-layout : fixed;
        border-collapse:separate;
        // border-spacing:0 10px;
        border-spacing:0 0;
        cursor: pointer;
        // border: 1px solid black;
        thead{
          tr{
            th{
              padding: 10px 0;
              position: sticky;
              top: 0;
              background: #e1eefb;
              z-index: 1;
              border-bottom: 1px solid #EEEEEE;
            }
          }
        }
        tbody{
          tr{
            td{
              text-align: center;
              padding: 20px 10px;
              position: relative;
              border-bottom: 1px solid #EEEEEE;
              // background: #e1eefb;
              // border: 1px solid black;
              // 表格内容
              .text{
                width: 100%;
                display: block;
                overflow: hidden;/*超出部分隐藏*/
                white-space: nowrap;/*不换行*/
                text-overflow:ellipsis;/*超出部分文字以...显示*/
                // border: 1px solid black;
              }
              //表格内部绝对定位元素
              .table_absolute{
                width: 100%;
                padding: 10px;
                background-color: white;
                z-index: 1;
                position: absolute;
                top: 100%;
                border: 1px solid black;
              }
              // 操作
              .operate{
                padding: 0 10px;
              }
            }
          }
          tr:hover{
            background: #e1eefb;
          }
        }
      }
    }
    // 开单主页分页
    .list_buttom{
      width: 100%;
      padding: 10px 0;
      text-align: center;
      position: absolute;
      bottom: 0;
      // border-top: 1px solid black;
      box-shadow: 2px 0 2px #888888;
      // border: 1px solid black;
    }
  }
</style>
